import { Sticker } from "schema/dist/src/sticker"
import { doc, getDoc } from "firebase/firestore"
import { firestoreDB } from "../App"

//GET POSTER DATA AND URLS
export const LoadPosterIDFromSticker = async (uuid: string) => {
  return new Promise<string>((resolve, reject) => {
    const docRef = doc(firestoreDB, "userStickers", uuid)
    getDoc(docRef)
      .then((doc) => {
        if (doc.exists()) {
          const sticker = doc.data() as Sticker
          resolve(sticker.posterUid)
        } else {
          resolve(uuid)
        }
      })
      .catch((error) => {
        resolve(uuid)
        reject(error)
        console.log("Error getting Sticker document:", error)
      })
  })
}
