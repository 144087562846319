import { doc, getDoc } from "firebase/firestore"
import { getStorage, ref, getDownloadURL } from "firebase/storage"
import { firestoreDB, setTabTitle } from "../App"
import { Poster } from "schema/dist/src/poster"

export interface LoadImageFromIDReturn {
  poster: Poster
  imgUrl: string
  videoURl: string
}

//GET POSTER DATA AND URLS
export const LoadImageFromID = async (uuid: string) => {
  const storage = getStorage()
  return new Promise<LoadImageFromIDReturn | undefined>((resolve, reject) => {
    const docRef = doc(firestoreDB, "userPosters", uuid)
    //GET POSTER DOC
    getDoc(docRef)
      .then((doc) => {
        if (doc.exists()) {
          const poster = doc.data() as Poster
          const stickerTitle = poster.title
          const imageStorageUrl = poster.imageLDURL
          setTabTitle(stickerTitle)

          //GET POSTER IMAGE URL
          const imageStorageRef = ref(storage, imageStorageUrl)
          getDownloadURL(imageStorageRef)
            .then((imageUrl) => {
              //GET VIDEO IMAGE URL
              const vidStorageUrl = poster.videoURL
              const vidStorageRef = ref(storage, vidStorageUrl)
              getDownloadURL(vidStorageRef)
                .then((videoURl) => {
                  let value: LoadImageFromIDReturn = {
                    poster: poster,
                    imgUrl: imageUrl,
                    videoURl: videoURl,
                  }
                  resolve(value)
                })
                .catch((error) => {
                  resolve(undefined)
                  reject("Error getting Image url")
                  console.log("Error getting Image url", error)
                })
            })
            .catch((error) => {
              resolve(undefined)
              reject("Error getting Image url")
              console.log("Error getting Image url", error)
            })
        } else {
          resolve(undefined)
          reject("No such document!")
          console.log("No such document!", uuid)
        }
      })
      .catch((error) => {
        resolve(undefined)
        reject(error)
        console.log("Error getting document:", error)
      })
  })
}

const LoadImage = async (file: LoadImageFromIDReturn) => {
  return new Promise<LoadImageReturn>((resolve, reject) => {
    const img = new Image()
    const vid = document.createElement("video")

    const value: LoadImageReturn = {
      poster: file.poster,
      img: img,
      vid: vid,
    }

    vid.src = file.videoURl
    vid.crossOrigin = "anonymous"
    vid.muted = true
    vid.playsInline = true
    vid.controls = false
    vid.hidden = true
    vid.loop = true

    img.onload = () => resolve(value)
    img.onerror = reject
    img.src = file.imgUrl
    img.crossOrigin = "Anonymous"
  })
}

export interface CompileReturn {
  posters: LoadImageReturn[]
  compiledURl: string
}

export interface LoadImageReturn {
  poster: Poster
  img: HTMLImageElement
  vid: HTMLVideoElement
}

const compiler = new (window as any).MINDAR.IMAGE.Compiler()

export const CompileFile = async (file: string[], callbackFunction: (progress: number) => void): Promise<CompileReturn> => {
  const images: HTMLImageElement[] = []
  const posters: LoadImageReturn[] = []

  const cleanedFiles = file.filter((v) => {
    return v !== ""
  })

  await Promise.all(
    cleanedFiles.map(async (fileUID) => {
      const image1Link = await LoadImageFromID(fileUID)

      if (image1Link === undefined) {
        return
      }

      const image1 = await LoadImage(image1Link)
      posters.push(image1)
      images.push(image1.img)
    })
  )

  if (posters.length === 0) {
    callbackFunction(100)
  }
  // const dataList = await compiler.compileImageTargets(images, callbackFunction)
  await compiler.compileImageTargets(images, callbackFunction)
  const exportedBuffer = await compiler.exportData()
  var blob = new Blob([exportedBuffer])
  var objectURL = URL.createObjectURL(blob)

  const value: CompileReturn = {
    posters: posters,
    compiledURl: objectURL,
  }
  return value
}
