import "./styles/App.css"
import { Routes, Route } from "react-router-dom"
import MainApp from "./app/main"
import Map from "./app/map"
import { initializeApp } from "firebase/app"
import { firebaseConfigStaging, firebaseConfigDev, firebaseConfigProd } from "schema/dist/src/firebase"
import { getFirestore } from "firebase/firestore"

export let setAppConfig = firebaseConfigDev
export let GoogleMapsAPIKey = ""

// https://tacomanator.medium.com/environments-with-create-react-app-7b645312c09d
const alias = process.env.REACT_APP_ENV
if (alias === "prod") {
  setAppConfig = firebaseConfigProd
  GoogleMapsAPIKey = "AIzaSyAIW7x6_tUjrZvjEU1Rs3tmmnJIBqV8TsA"
} else if (alias === "dev") {
  setAppConfig = firebaseConfigDev
  GoogleMapsAPIKey = "AIzaSyBAqgSwAvCNCIIuvXzFT7Gh9coVokdS1k8"
} else if (alias === "staging") {
  setAppConfig = firebaseConfigStaging
  GoogleMapsAPIKey = "AIzaSyA-IRW9vQvoYYiTt9fPZd24C26bWmKnm-I"
} else {
  console.error("PLEASE SET APP ENVIRONMENT")
}

export let isUsingEmulator = true
export const LocalIP = "0.0.0.0"
export const FunctionsLocalPort = 5033
var env = process.env.NODE_ENV || "development"

if (env === "production") {
  isUsingEmulator = false
}

export const firebaseApp = initializeApp(setAppConfig)
export const firestoreDB = getFirestore(firebaseApp)

export function setTabTitle(title: string) {
  document.title = title
}

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<MainApp />} />
        <Route path="/map" element={<Map />} />
        <Route path="sticker/:stickerID" element={<MainApp />} />
        <Route path="poster/:posterID" element={<MainApp />} />
      </Routes>
    </div>
  )
}

export default App
