import React from "react"
import { CompileReturn, LoadImageReturn } from "./compile"
import styles from "./styles/home.module.css"
import { useNavigate } from "react-router-dom"

interface LoadingViewProps {
  configuration: CompileReturn | undefined
  progress: number
}

export const LoadingAssetsView: React.FC<LoadingViewProps> = ({ progress }): JSX.Element | null => {
  const isHidden = progress.toFixed(0) === "100"

  if (isHidden) {
    return null
  }

  let progressText = ""

  if (progress < 10) {
    progressText = "Welcome to Revivar"
  } else if (progress < 20) {
    progressText = "Downloading app preview"
  } else if (progress < 50) {
    progressText = "This may take longer than 30 seconds"
  } else if (progress < 70) {
    progressText = "Full experience available on the App-store"
  } else if (progress < 80) {
    progressText = "Mixing last pieces of the magical recipe"
  } else if (progress > 90) {
    progressText = "Get ready to point your camera to picture"
  }

  return (
    <div className={styles.LoadingView}>
      <div className={styles.LoadingViewLogo}></div>
      <div className={styles.LoadingViewProgressBarContainer}>
        <div className={styles.LoadingViewProgressBar} style={{ width: progress.toString() + "%" }}></div>
      </div>
      <div className={styles.LoadingViewProgressInfo}>{progressText}</div>
    </div>
  )
}

interface MainMenuContainerProps {
  focusedPoster: LoadImageReturn | undefined
  onPlay: () => void
  isPlaying: boolean
  onMuted: () => void
  isMuted: boolean
}

export const MainMenuContainer: React.FC<MainMenuContainerProps> = ({
  focusedPoster,
  onPlay,
  isPlaying,
  onMuted,
  isMuted,
}): JSX.Element => {
  return (
    <div className={styles.MainMenuContainer}>
      {focusedPoster === undefined ? (
        <div className={styles.MainMenuControlIdle}>REVIVAR</div>
      ) : (
        <div className={styles.MainMenuControlActive}>
          <div className={isPlaying ? styles.MainMenuControlPauseButton : styles.MainMenuControlPlayButton} onClick={onPlay} />
          <div
            className={isMuted ? styles.MainMenuControlMuteButton : styles.MainMenuControlUnMuteButton}
            onClick={(e) => {
              e.stopPropagation()
              onMuted()
            }}
          />
          <div className={styles.MainMenuControlTitle}>{focusedPoster.poster.title.toLocaleUpperCase()}</div>
        </div>
      )}
    </div>
  )
}

// interface MainMenuButtonsProps {
//   configuration: CompileReturn | undefined
// }

// export const MainMenuButtons: React.FC<MainMenuButtonsProps> = ({ configuration }): JSX.Element => {
//   return (
//     <div className={styles.MainMenuButtons}>
//       <div className={styles.MainMenuButtonsContianer}>
//         <div className={styles.MainMenuButton}>
//           <div
//             className={styles.MainMenuButtonIcon + " " + styles.MainMenuButtonRevivarIcon}
//             onClick={() => {
//               window.open("https://www.revivar.app")
//             }}
//           />
//         </div>
//         <div className={styles.MainMenuButton}>
//           <div className={styles.MainMenuButtonIcon + " " + styles.MainMenuButtonTextIcon}>{configuration?.posters.length}</div>
//         </div>
//       </div>
//     </div>
//   )
// }

interface DownloadAppButtonProps {}

export const DownloadAppButton: React.FC<DownloadAppButtonProps> = (): JSX.Element => {
  return (
    <div
      className={styles.DownloadAppButtonContainer}
      onClick={() => {
        window.open("https://apps.apple.com/us/app/revivar/id1559129541")
      }}
    >
      <span>
        For a quicker and better experience
        <div className={styles.DownloadAppButton}></div>
      </span>
    </div>
  )
}

export const TopAppButton: React.FC<DownloadAppButtonProps> = (): JSX.Element => {
  return (
    <div className={styles.TopAppButtonContainer}>
      <DownloadAppButton />
      <OpenAppButton
        onClick={() => {
          window.open("https://www.revivar.app")
        }}
        imageURL={styles.MainMenuButtonRevivarIcon}
      />
    </div>
  )
}

export const AppButton: React.FC<DownloadAppButtonProps> = (): JSX.Element => {
  return (
    <div className={styles.AppButton}>
      <OpenAppButton
        onClick={() => {
          window.open("https://www.revivar.app/shop")
        }}
        imageURL={styles.MainMenuButtonShop}
      />
    </div>
  )
}

export const MapButton: React.FC<DownloadAppButtonProps> = (): JSX.Element => {
  const nav = useNavigate()
  return (
    <div className={styles.AppButton}>
      <OpenAppButton
        onClick={() => {
          nav("/map")
        }}
        imageURL={styles.MainMenuButtonMap}
      />
    </div>
  )
}

export const CloseButton: React.FC<DownloadAppButtonProps> = (): JSX.Element => {
  const nav = useNavigate()
  return (
    <div className={styles.AppButton}>
      <OpenAppButton
        onClick={() => {
          nav("/")
        }}
        imageURL={styles.CloseMenuButtonMap}
      />
    </div>
  )
}
interface OpenAppButtonProps {
  onClick: () => void
  imageURL: string
}

export const OpenAppButton: React.FC<OpenAppButtonProps> = ({ onClick, imageURL }): JSX.Element => {
  return (
    <div className={styles.OpenAppButtonAppButtonContainer}>
      <span className={styles.MainMenuButtonIcon + " " + imageURL} onClick={onClick} />
    </div>
  )
}

export const LogoButton: React.FC<DownloadAppButtonProps> = (): JSX.Element => {
  return <div className={styles.DownloadAppbutton}> Download App</div>
}

export const LoadingPreview: React.FC<{ title: string }> = ({ title }): JSX.Element => {
  return (
    <div className={styles.LoadingPreview}>
      <div className={styles.DownloadAppButtonContainer}>
        <span>{title}</span>
      </div>
    </div>
  )
}
